.btn {
    background-color: $color-1;
    color: #F2F2F2;
    border: none;
    border-radius: 0;
    height: 60px;
    width: 135px;

    font-weight: bold;
    font-size: $font-size-16;
    transition: all 0.2s ease-in;

    &:hover {
        transition: all 0.2s ease-in;
        color: $color-3;
    }
}
