.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 70%;
        left: 5%;
        width: 100%;
        transform: translateY(-50%);

        &--title {
            font-weight: bold;
            font-size: $font-size-80;
            color: #F2F2F2;
            text-transform: initial;
        }
    }
}
@media screen and (max-width: 1600px) {
    .module-header {
        &--caption {
            &--title {
                font-size: 3.5rem;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .module-header {
            &--caption {
                &--title {
                    font-size: 3rem;
                }
            }
        }
        @media screen and (max-width: $size-xs-max) {
            .module-header {
                &--caption {
                    width: 90%;
                    &--title {
                        font-size: 2.5rem;
                        br {
                            display: none;
                        }
                    }
                }
            }
            @media screen and (max-width: 360px) {
                .module-header {
                    &--caption {
                        &--title {
                            font-size: 2.4rem;
                        }
                    }
                }
            }
        }
    }
}



.module-header.landing {
    .module-header--caption {
        top: 28%;
        @media screen and (max-width: $size-sm-max) {
            top: 30%;
            @media screen and (max-width: $size-xs-max) {
                top: 29%;
            }
        }
    }
}