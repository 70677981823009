// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


a.btn-red {
    background: $color-1;
    width: 205px;
    height: 60px;
    transition: 0.3s;
    h5 {
        font-weight: bold;
        font-size: $font-size-16;
        color: $color-white;
        text-transform: uppercase;
    }
    .icon {
        opacity: 0;
        transition: 0.3s;
    }
    &:hover {
        width: 210px;
        transition: 0.3s;
        .icon {
            opacity: 1;
            transition: 0.3s;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .text.pr2 {
            padding: 0;
        }
        .icon {
            display: none;
        }
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';





/*******************************************************************************
* LANDING PAGES
*******************************************************************************/

#section-text-image.landing {
    padding: 75px 5%;
    .text-side {
        width: 45%;
        margin-right: 50px;
        padding-top: 0;
        h3 {
            font-weight: 500;
            font-size: $font-size-24;
            color: #000;
            text-transform: initial;
            padding-bottom: 25px;
        }
        a.btn-red {
            background: #0E2B87;
            width: 205px;
            height: 60px;
            transition: 0.3s;
            margin-top: 50px;
        }
    }
    .image-side {
        width: 55%;
        h3 {
            color: #000;
        }
    }
    @media screen and (max-width: 1220px){
        flex-direction: column;
        .text-side {
            width: 100%;
            padding-top: 0;
            margin: 0 0 75px 0;
        }
        .image-side {
            width: 100%;
        }
        @media screen and (max-width: $size-xs-max){
            .image-side {
                .form-group.col-xs-12.col-sm-6.pl0 {
                    padding: 0;
                }
            }
            .text-side a.btn-red {
                width: 160px;
            }
        }
    }
}




/*******************************************************************************
* ACCUEIL
*******************************************************************************/

.section_video {
    &__wrapper {
        position: relative;
        video {
            width: 100%;
            height: auto;
        }
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 3px;
            background:rgba(0, 0, 0, .2);//hsla(9, 100%, 64%, 0.4);//
            &.mecanique {
                bottom: 0;
            }
        }
        &_caption {
            position: absolute;
            top: 35%;
            left: 5%;
            right: 5%;
            h3, h2, p {
                text-transform: initial;
                color: $color-white;
            }
            h3 {
                font-weight: normal;
                font-size: $font-size-34;
            }
            h2 {
                font-weight: bold;
                font-size: $font-size-80;
                padding: 25px 0;
            }
            p {
                width: 35%;
                line-height: 1.6;
            }
            div.main {
                flex: 0 0 auto;
                margin-right: auto;
            }
            &.mecanique {
                top: 50%;
                p {
                    width: 82%;
                }
            }
            &.coordonnees {
                top: 75%;
                left: 5%;
            }  
        }

        .section-logo {
            position: absolute;
            right: 5%;
            top: 30px;
            @media screen and (max-width: 1024px){
                top: 45px;
            }
        }

        &_caption2 {
            position: absolute;
            bottom: 3px;
            left: 0;
            width: 40%;
            height: 155px;
            margin-right: 5%;
            padding: 0 5%;
            background: $color-3;
            .icon {
                padding-right: 60px;
            }
            .text {
                border-left: 2px solid $color-1;
                padding-left: 15px;
                h4 {
                    font-weight: 600;
                    font-size: $font-size-18;
                    color: $color-1;
                    padding-bottom: 5px;
                }
                div.phonenumber {
                    font-weight: 500;
                    font-size: $font-size-36;
                    color: $color-1;
                }
            }
        }
        &_caption3 {
            position: absolute;
            bottom: 3px;
            right: 0;
            width: 40%;
            height: 240px;
            padding: 35px 50px;
            background: #FFFFFFE8;
            .section-text {
                h4 {
                    font-weight: bold;
                    font-size: $font-size-34;
                    color: $color-black;
                    padding: 10px 0 25px 0;
                }
                .border-btn {
                    .border {
                        background: $color-black;
                        height: 3px;
                        width: 75px;
                        margin-right: 40%;
                        margin-bottom: 5px;
                    }
                } 
            }
        }
    }
    @media screen and (max-width: 1650px){
        &__wrapper {
            &_caption {
                top: 30%;
                p {
                    width: 41%;
                }
            }
        }
        @media screen and (max-width: 1550px){
             &__wrapper {
                &_caption {
                    left: 3.5%;
                }
                &_caption2 {
                    padding: 0 4.5%;
                }
            }
            @media screen and (max-width: 1460px){
                &__wrapper {
                    &_caption {
                        h3 {
                            font-size: 1.5rem;
                        }
                        h2 {
                            font-size: 3.5rem;
                            padding: 15px 0;
                        }
                        p {
                            width: 46%;
                        }
                    }
                }
                @media screen and (max-width: 1410px){
                    &__wrapper {
                        &_caption {
                            div.main {
                                flex: initial;
                            }
                        }
                    }
                    @media screen and (max-width: 1375px){
                        &__wrapper {
                            &_caption3 {
                                .section-text h4 {
                                    font-size: 1.5rem;
                                }
                            }
                        }
                        @media screen and (max-width: 1225px){
                            &__wrapper {
                                &_caption {
                                    left: 4%;
                                    top: 27%;
                                    p {
                                        width: 60%;
                                    }
                                }
                                &_caption2 {
                                    padding: 0 5%;
                                    position: initial;
                                    width: 100%;
                                    margin-right: 0;
                                    margin-top: -3px;
                                }
                                &_caption3 {
                                    position: initial;
                                    height: initial;
                                    width: 100%;
                                    padding: 0;
                                    background: lightgrey;
                                    border-top-left-radius: 0;
                                    .section-text {
                                        padding: 50px 5%;
                                    }
                                }
                                &_overlay {
                                    bottom: 410px;
                                }
                            }
                            @media screen and (max-width: 1024px){
                                &__wrapper {
                                    &_caption {
                                        top: 23%;
                                        h2 {
                                            font-size: 3rem;
                                        }
                                    }
                                    &_overlay {
                                        bottom: 409px;
                                    }
                                }
                                @media screen and (max-width: $size-sm-max){
                                    &__wrapper {
                                        &_caption {
                                            top: 20%;
                                            h2 {
                                                font-size: 2.8rem;
                                            }
                                            p {
                                                width: 75%;
                                            }
                                        }
                                        &_overlay {
                                            bottom: 403px;
                                        }
                                    }
                                    @media screen and (max-width: $size-xs-max){
                                        &__wrapper {
                                            &_caption {
                                                flex-direction: column;
                                                top: 30%;
                                                p {
                                                    width: 100%;
                                                }
                                            }
                                            &_overlay {
                                                bottom: 396px;
                                            }
                                        }
                                        @media screen and (max-width: 375px){
                                            &__wrapper {
                                                &_caption {
                                                    top: 27%;
                                                }
                                            }
                                            @media screen and (max-width: 360px){
                                                &__wrapper {
                                                    &_caption {
                                                        top: 25%;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }   
        }
    }
}

#section-text-image {
    padding: 50px 0 100px 5%;
    .text-side {
        width: 36%;
        margin-right: 50px;
        padding-top: 7%;
        h4 {
            font-weight: bold;
            font-size: $font-size-34;
            color: $color-black;
            padding-bottom: 25px;
        }
    }
    .image-side {
        width: 62%;
    }
    @media screen and (max-width: 1610px) {
        .text-side h4 br {  
            display: none;
        }
        @media screen and (max-width: 1430px) {
            .text-side {
                padding-top: 4%;
            }
            @media screen and (max-width: 1400px) {
                .text-side {
                    width: 40%;
                }
                @media screen and (max-width: 1220px) {
                    flex-direction: column-reverse;
                    padding: 35px 5%;
                    .text-side {
                        padding-top: 0;
                        margin: 35px 0 0 0;
                        width: 100%;
                    }
                    .image-side {
                        width: 100%;
                    }
                }
            }
        }
    }
    &.mecanique {
        padding: 50px 5% 100px 5%;
        .text-side {
            padding-top: 4%;
            width: 45%;
            @media screen and (max-width: 1670px) {
                padding-top: 2%;
                @media screen and (max-width: 1620px) {
                    padding-top: 1%;
                }
            }
        }
        .image-side {
            width: 55%;
        }
        @media screen and (max-width: 1600px) {
            align-items: center;
            @media screen and (max-width: 1220px) {
                flex-direction: column;
                padding: 50px 5% 35px 5%;
                .text-side {
                    padding-top: 0;
                    margin: 0 0 35px 0;
                    width: 100%;
                }
                .image-side {
                    width: 100%;
                }
            }
        }
    }
    &.electrique {
        .text-side {
            padding-top: 0;
        }
        @media screen and (max-width: 1760px) {
            .text-side {
                h4 br {
                    display: none;
                }
            }
            @media screen and (max-width: 1650px) {
                .text-side {
                    width: 45%;
                }
                @media screen and (max-width: 1450px) {
                    flex-direction: column-reverse;
                    .image-side {
                        width: 70%;
                        margin-bottom: 35px;
                    }
                    .text-side {
                        width: 100%;
                        margin: 0;
                    }
                    @media screen and (max-width: $size-sm-max) {
                        .image-side {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

#section-image-text {
    padding: 0 5% 35px 0;
    .text-side {
        position: absolute;
        right: 0%;
        background: $color-white;
        padding: 35px 75px 35px 35px;
        width: 35%;
        h4 {
            font-weight: bold;
            font-size: $font-size-34;
            color: $color-black;
            padding-bottom: 20px;
        }
        p {
            width: 85%;
        }
        a.btn-red {
            width: 340px;
            margin-top: 25px;
            &:hover {
                width: 345px;
                transition: 0.3s;
            }
        }
    }
    @media screen and (max-width: 1745px) {
        .text-side {
            width: 40%;
        }
        @media screen and (max-width: 1540px) {
            .text-side {
                width: 50%;
            }
            @media screen and (max-width: 1220px) {
                flex-direction: column;
                padding: 0 5% 35px 5%;
                .text-side {
                    width: 100%;
                    position: initial;
                    padding: 35px 0;
                    p {
                        width: 100%;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    .text-side {
                        padding: 35px 0;
                        h4 br {
                            display: none;
                        }
                        a.btn-red {
                            width: 240px;
                        }
                    }
                }
            }
        }
    }
    &.electrique {
        .text-side {
            background: rgba(255,255,255,0.85);
            border-radius: 10px;
            width: 40%;
            padding: 45px 75px 45px 50px;
            .border-btn {
                .border {
                    background: $color-3;
                    height: 3px;
                    width: 75px;
                    margin-right: 40%;
                }
                a.btn-red {
                    width: 325px;
                    &:hover {
                        width: 330px;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        width: 235px;
                    }
                }
                .border {
                    margin-right: 20%;
                }
            }
            @media screen and (max-width: 1600px) {
                width: 50%;
                h4 {
                    font-size: 1.5rem;
                }
                @media screen and (max-width: 1220px) {
                    width: 100%;
                    padding: 50px 0;
                }
            }
        }
    }
}

/*******************************************************************************
* MÉCANIQUE GÉNÉRALE
*******************************************************************************/

#section-title-image {
    .section-title {
        padding: 40px 5% 75px 5%;
        .title {
            width: 65%;
            h2 {
                font-weight: bold;
                font-size: $font-size-60;
                color: $color-black;
                text-transform: uppercase;
            }
        }
        .border {
            background: $color-1;
            height: 3px;
            width: 535px;
        }
    }
    @media screen and (max-width: 1260px) {
        .section-title {
            .title {
                h2 {
                    font-size: 2.3rem;
                }  
            }
        } 
        @media screen and (max-width: 1024px) {
            .section-title {
                .title {
                    h2 {
                        font-size: 2rem;
                    }  
                }
            } 
            @media screen and (max-width: $size-xs-max) {
                .section-title {
                    flex-direction: column;
                    .title {
                        margin: 0 0 25px 0;
                        h2 {
                            font-size: 2.5rem;
                            text-align: center;
                        }
                    }
                    .border {
                        width: 100%;
                    }
                } 
            }
        }
    }
}

#section-bloc-image {
    padding: 35px 5% 0 5%;
    .left-side {
        width: 57%;
        background: #F2F2F2;
        margin-right: 15px;
        padding: 75px 50px;
        h4 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-1;
            padding-bottom: 25px;
        }
        p {
            padding-bottom: 25px;
        }
        a.btn-red {
            width: 180px;
            &:hover {
                width: 185px;
            }
            &.long {
                width: 205px;
                &:hover {
                    width: 210px;
                }
            }
            &.small {
                width: 115px;
                &:hover {
                    width: 120px;
                }
            }
        }
    }
    .right-side {
        &.pic01 {
            width: 43%;
            background: url(../images/mecanique_section03_img01.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/mecanique_section03_img01_m.jpg) no-repeat;
                background-size: cover;
            }
        }
        &.pic03 {
            background: url(../images/mecanique_section05_img01.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/mecanique_section05_img01_m.jpg) no-repeat;
                background-size: cover;
            }
        }
    }
    @media screen and (max-width: 1750px) {
        .left-side p {
            br {
                display: none;
            }
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column-reverse;
            .left-side {
                width: 100%;
                margin-right: 0;
            }
            .right-side.pic01 {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                .left-side {
                    padding: 60px 25px;
                    a.btn-red {
                        width: 200px;
                    }
                }
            }
        }
    }
}

#section-image-bloc {
    padding: 35px 5% 0 5%;
    .left-side {
        &.pic02 {
            background: url(../images/mecanique_section04_img01.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/mecanique_section04_img01_m.jpg) no-repeat;
                background-size: cover;
            }
        }
    }
    .right-side {
        width: 57%;
        background: #F2F2F2;
        margin-left: 15px;
        padding: 75px 50px;
        h4 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-1;
            padding-bottom: 25px;
        }
        p {
            padding-bottom: 25px;
        }
        a.btn-red {
            width: 260px;
            &:hover {
                width: 265px;
            }
        }
    }
    @media screen and (max-width: 1750px) {
        .right-side p {
            br {
                display: none;
            }
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            .right-side {
                width: 100%;
                margin-left: 0;
            }
            @media screen and (max-width: $size-xs-max) {
                .right-side {
                    padding: 60px 25px;
                    a.btn-red {
                        width: 200px;
                    }
                }
            }
        }
    }
}

#section-background {
    margin: 0 5%;
    padding-top: 35px;
    position: relative;
    .title {
        position: absolute;
        right: 0;
        bottom: 0;
        background: $color-1;
        padding: 30px 55px 30px 30px;
        h2 {
            font-weight: bold;
            font-size: $font-size-16;
            color: #F2F2F2;
        }
    }
}

#section-text {
    background: #F2F2F2;
    padding: 100px 5%;
    text-align: center;
    margin: 25px 5%;
    h3 {
        font-weight: bold;
        font-size: $font-size-48;
        color: $color-1;
        text-transform: initial;
    }
    @media screen and (max-width: $size-md-max) {
        h3 {
            font-size: 2.3rem;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 75px 5%;
            h3 {
                font-size: 1.8rem;
            }
        }
    }
}

/*******************************************************************************
* MÉCANIQUE ÉLECTRIQUE
*******************************************************************************/





/*******************************************************************************
* EMPLOIS
*******************************************************************************/

#section-info {
    padding: 50px 0;
    border-bottom: 1px solid $color-1;
    margin: 0 5%;
    .left-side {
        width: 50%;
        margin-right: 50px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #1A1818;
            line-height: 1.4;
        }
    }
    .right-side {
        width: 50%;
        h5 {
            font-size: $font-size-26;
            color: #1A1818;
            line-height: 1.5;
        }
    }
    @media screen and (max-width: 1210px){
        .left-side {
            h3 br {
                display: none;
            }
        }
        @media screen and (max-width: 1100px){
            flex-direction: column;
            .left-side {
                width: 100%;
                margin: 0 0 35px 0;
            }
            .right-side {
                width: 100%;
            }
        }
    }
}

#section-emploi {
    padding: 75px 5% 0 5%;
    div.section-wrapper {
        display: inline-grid;
        margin: auto;
        width: 90%;
        max-width: 1600px;;
        justify-items: stretch;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        @media screen and (max-width: $size-sm-max){
            grid-template-columns: 1fr;
        }
    }
    div.left-side, div.right-side {
        width: 50%;
        @media screen and (max-width: $size-xs-max){
          width: 100%;
        }
    }

    section.emplois-liste {
        display: flex;
        flex-wrap: wrap;
        div.item-poste {
            display: inline-block;
            margin-bottom: 20px;
            background-color: #F2F2F2;
            transition: .5s;
            width: 49%;

            div.title {
                background-color: $color-1;
                padding: 30px 7%;
                h4 {
                    color: #F2F2F2;
                    font-size: 20px;
                    font-weight: bold;
                    text-transform: initial;
                }
                @media screen and (max-width: 1325px){
                    padding: 30px 5%;
                }
            }
            div.soustitre {
                padding: 30px 7%;
                .icon {
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: $color-black;
                    margin-right: 50px;
                    padding-bottom: 10px;
                    .fa {
                        font-size: 20px;
                        margin-right: 10px;
                        color: $color-3;
                    }
                    @media screen and (max-width: 1420px){
                        margin-right: 25px;
                        @media screen and (max-width: 1270px){
                            margin-right: 0;
                            width: 100%;
                            margin-bottom: 15px;
                            @media screen and (max-width: 1110px){
                                margin-right: 25px;
                                width: initial;
                                margin-bottom: 0;
                                @media screen and (max-width: $size-xs-max){
                                    margin-right: 0;
                                    width: 100%;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1325px){
                    padding: 30px 5%;
                }
            }
            div.content-full {
                padding: 0 7% 30px 7%;
                h4, h3, h2 {
                    font-size: 18px;
                    color: $color-black;
                    padding: 15px 0;
                    font-weight: bold;
                }
                ul {
                    padding-left: 0;
                }
                p, li {
                    color: $color-black;
                    font-weight: normal;
                    font-size: 16px;
                    padding-left: 25px;
                    line-height: 2;
                    text-transform: initial;
                    list-style: none;
                    @media screen and (max-width: 1420px){
                        padding-left: 0;
                        line-height: 1.2;
                        padding-bottom: 15px;
                    }
                }
                .btn-wrapper {
                    max-width: 145px;
                    margin-top: 50px;
                    .btn {
                        padding: 20px 5px;
                        background-color: $color-1;
                        border: none;
                        font-weight: bold;
                        font-size: 16px;
                        &:hover {
                            color: $color-3;
                            border: none;
                            opacity: 0.7;
                        }
                    }
                }
                @media screen and (max-width: 1325px){
                    padding: 0 5% 30px 5%;
                }
            }
            &:nth-child(odd) {
                margin-right: 20px;
                @media screen and (max-width: 1110px){
                    margin-right: 0;
                }
            }
            @media screen and (max-width: 1110px){
                width: 100%;
                margin: 0 0 15px 0;
            }
        }
    }
}

#section-images {
    padding: 0 5% 35px 5%;
    .image {
        width: 49%;
        &.first {
            margin-right: 20px;
            margin-bottom: 20px;
        }
        &.full {
            width: 100%;
        }
    }
    @media screen and (max-width: 1110px){
        .image {
            &.first {
                margin-right: 19px;
                margin-bottom: 19px;
            }
        }
        @media screen and (max-width: 1055px){
            .image {
                width: 100%;
                margin-bottom: 20px;
                &.first {
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

section.section-form-emploi {
    padding: 60px 10% 25px 10%;
    margin-bottom: 25px;
    background-color: #F2F2F2;
    h3 {
        font-weight: bold;
        color: #3D4251;
        font-size: $font-size-30;
        padding-bottom: 20px;
        @media screen and (max-width: 360px) {
            font-size: 1.4rem;
        }
    }
    .form-group {
        margin-bottom: -10px;
    }
    input {
        height: 95px;
        border: 1px solid #A6ADB4;
    }
    textarea#message {
        border: 1px solid #A6ADB4;
    }
    textarea::placeholder {
        padding: 15px 5px !important;
    }
    form label.filedoc {
        background: $color-1;
        width: 200px;
        color: $color-white;
        font-weight: bold;
        font-size: 16px;
        &:hover {
            color: $color-3;
            opacity: 0.7;
        }
    }
    .btn {
        border-radius: 0;
        border: none;
        background: $color-1;
        font-weight: bold;
        font-size: 16px;
        padding: 20px;
        &:hover {
            color: $color-3;
            border: none;
            opacity: 0.7;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .pl0 , .pr0, {
            padding: 0!important;
        }
        form label.filedoc {
            width: 230px;
            margin-right: 0;
        }
        form .buttons {
            justify-content: center;
            margin: 0 0 50px 0;
        }
        .btn-submit {
            margin-top: 15px;
            .btn.submit-emploi {
                width: 230px;
            }
        }
    }
    @media screen and (max-width: 1325px){
        padding: 60px 8% 25px 8%;
        @media screen and (max-width: $size-sm-max){
            padding: 60px 5% 25px 5%;
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 50px 5% 0 5%;
    .left-side {
        width: 40%;
        h2 {
            font-weight: bold;
            font-size: $font-size-34;
            color: #171717;
        }
    }
    .right-side {
        padding-top: 5px;
        h4 {
            font-weight: bold;
            font-size: $font-size-22;
            color: $color-black;
            text-transform: uppercase;
            padding-bottom: 5px;
        }
        h5 {
            font-weight: 500;
            font-size: $font-size-20;
            color: #909090;
        }
    }
    @media screen and (max-width: 1200px) {
        .left-side {
            width: 45%;
        }
        @media screen and (max-width: 1024px){
            .left-side {
                width: 55%;
            }
            @media screen and (max-width: $size-sm-max){
                .left-side {
                    width: 60%;
                }
                @media screen and (max-width: $size-xs-max){
                    flex-direction: column;
                    .left-side {
                        width: 100%;
                        padding: 0px 0 25px 0;
                    }
                    .right-side {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}

#section-form-heures {
    padding: 75px 5% 0 5%;
    .section-form {
        width: 50%;
        margin-right: 10%;
        h3 {
            font-weight: bold;
            font-size: $font-size-34;
            color: #171717;
            padding-bottom: 50px;
        }
    }
    .right-side {
        width: 40%;
        h3 {
            font-weight: bold;
            font-size: $font-size-34;
            color: $color-black;
            text-transform: initial;
            padding-bottom: 10px;
        }
        .border {
            background: $color-1;
            height: 1px;
            width: 525px;
            margin-bottom: 50px;
        }
        .jours-heures {
            h4, h5 {
                font-weight: bold;
                font-size: $font-size-18;
                color: $color-black;
                line-height: 2.3;
            }
            h5 {
                font-weight: normal;
            }
            .jour {
                width: 39%;
            }
        }
        .section-title {
            background: $color-1;
            width: 525px;
            padding: 25px;
            margin-top: 25px;
            h2 {
                font-weight: bold;
                font-size: $font-size-28;
                color: #F2F2F2;
                text-align: center;
            }
        }
    }
    @media screen and (max-width: $size-md-max) {
        .right-side {
            .border {
                width: 450px;
            }
            .section-title {
                width: 450px;
                h2 {
                    font-size: 1.2rem;
                    text-align: center;
                }
            }
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column-reverse;
            .right-side {
                width: 100%;
                margin: 0 0 75px 0;
            }
            .section-form {
                width: 100%;
                margin-right: 0;
            }
            @media screen and (max-width: $size-xs-max) {
                .right-side {
                    .border {
                        width: 100%;
                    }
                    .section-title {
                        width: 100%;
                    }
                }
                .section-form {
                    h3 {
                        text-align: center;
                    }
                    .col-sm-6.pr0, .col-sm-6.pl0 {
                        padding: 0;
                    }
                }
            }
        }
    }
}

#section-map {
    margin-bottom: 35px;
}

div#content {
    h3#firstHeading {
        font-size: $font-size-22;
        padding-bottom: 10px;
        color: $color-1;
    }
    p a {
        color: $color-black;
    }
}




/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
   

}
