footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: $size-footer-width-desktop;
        margin: 0 auto;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-around;
        @extend .text-color-grey;
        @extend .font-footer;
        flex-direction: column;
        .logo-footer {
            position: absolute;
            left: 5%;
        }
        .copyright {
            font-size: $font-size-16;
            color: #DEEBEE;
        }
        @media screen and (max-width: $size-xs-max) {
            .logo-footer {
                position: initial;
                margin-bottom: 25px;
            }
            .copyright {
                text-align: center;
                line-height: 1.5;
            }
        }
    }
    @media screen and (max-width: $size-xs-max) {
        min-height: 275px;
    }
}
